import Layout from "../components/layout"
import SEO from "../components/seo"

import React, { Component } from "react"
// import Img from "gatsby-image"
import { graphql } from "gatsby"

import lazySizes from 'lazysizes'

import "../styles/style.scss"

const getWindowType = (window) => {
    if (typeof window !== `undefined` && window.innerWidth < 450) {
        return 'mobile'
    } else if (typeof window !== `undefined` && window.innerWidth < 1367) {
        return 'tablet'
    } else {
        return 'desktop'
    }
}
const animateMetal = (material, startValue, endValue, time) => {
    const tick = 10;
    const direction = startValue - endValue > 0 ? 'down' : 'up';
    let start = Date.now(); // remember start time
    let value = startValue;
    let tickValue;
    if (direction === 'up') {
        tickValue = (endValue - startValue) / (time / tick)
    } else {
        tickValue = (startValue - endValue) / (time / tick)
    }
    let timer = setInterval(function() {
      // how much time passed from the start?
      let timePassed = Date.now() - start;
      if (timePassed >= time) {
        clearInterval(timer); // finish the animation after 2 seconds
        return;
      }
      value = direction === 'up' ? value + tickValue : value - tickValue;
      material.pbrMetallicRoughness.setMetallicFactor([value]);
    }, tick);
}


const animateExposure = (modelViewerParameters, startValue, endValue, time) => {
    const tick = 10;
    const direction = startValue - endValue > 0 ? 'down' : 'up';
    let start = Date.now(); // remember start time
    let value = startValue;
    let tickValue;
    if (direction === 'up') {
        tickValue = (endValue - startValue) / (time / tick)
    } else {
        tickValue = (startValue - endValue) / (time / tick)
    }
    let timer = setInterval(function() {
      // how much time passed from the start?
      let timePassed = Date.now() - start;
      if (timePassed >= time) {
        clearInterval(timer); // finish the animation after 2 seconds
        return;
      }
      value = direction === 'up' ? value + tickValue : value - tickValue;
      modelViewerParameters.current.exposure = value;
    }, tick);
}


const animateRoughness = (material, startValue, endValue, time) => {
    const tick = 10;
    const direction = startValue - endValue > 0 ? 'down' : 'up';
    let start = Date.now(); // remember start time
    let value = startValue;
    let tickValue;
    if (direction === 'up') {
        tickValue = (endValue - startValue) / (time / tick)
    } else {
        tickValue = (startValue - endValue) / (time / tick)
    }
    let timer = setInterval(function() {
      // how much time passed from the start?
      let timePassed = Date.now() - start;
      if (timePassed >= time) {
        clearInterval(timer); // finish the animation after 2 seconds
        return;
      }
      value = direction === 'up' ? value + tickValue : value - tickValue;
     material.pbrMetallicRoughness.setRoughnessFactor([value]);
    }, tick);
}


class Homepage extends Component {

    constructor(props) {
        super(props);
        this.modelViewerIntroducion = React.createRef();
        this.introductionElem = React.createRef();

        this.introMouseTimeout = null;
        this.windowType = typeof window !== `undefined` ? getWindowType(window) : 'desktop';


    }

    state = {
        polygonDynamicsAnimation: '',
        poster: ''
    }



    componentDidMount() {

        if (typeof window !== `undefined`) {
          window.addEventListener("resize", this.resize.bind(this));
          this.resize();
        }

        try {

            require('lazysizes/plugins/attrchange/ls.attrchange')
            require('lazysizes/plugins/unveilhooks/ls.unveilhooks.js')

            const UIkit = require("uikit/dist/js/uikit")

            UIkit.lightbox('.lightbox', {
                animation: 'fade',
                videoAutoplay: true,
                velocity: 10,
                preload: 0,
                container: 'main'
            });


            UIkit.scrollspy('.introduction', {
                repeat: true,
                hidden: false,
                offsetTop: typeof window !== `undefined` ? -(window.innerHeight / 2) : -1367
            });

            UIkit.scrollspy('.lightbox', {
                repeat: true,
                hidden: false,
                offsetTop: typeof window !== `undefined` ? -(window.innerHeight / 2) : -1367
            });

            UIkit.scrollspy('footer', {
                repeat: true,
                hidden: false
            });

            UIkit.util.on('.introduction', 'inview', () => {
                const introductionImage = this.props.data.wpgraphql.pages.nodes[0].acfDocs.sectionIntroduction[0].modelviewerenvironmentimage.medium;
                this.modelViewerIntroducion.current.environmentImage = introductionImage;
            });

            UIkit.util.on('.lightbox', 'inview', () => {
                // console.log('lightboxview');

            });

            window.addEventListener('mousemove', this.handleMouseMove);




            require("@google/model-viewer/dist/model-viewer")


        } catch (e) {
            console.error(e)
        }

        // this.modelViewerIntroducion.current.addEventListener('environment-change', () => {
        // });

        this.modelViewerIntroducion.current.addEventListener('load', this.tst);




    }


    componentWillUnmount() {
      if (typeof window !== `undefined`) {
        window.removeEventListener("resize", this.resize.bind(this));
        window.removeEventListener('mousemove', this.handleMouseMove);
      }
      this.modelViewerIntroducion.current.removeEventListener('load', this.tst);
      // this.modelViewerIntroducion.current.removeEventListener('environment-change');
    }

    tst = () => {
      this.modelViewerIntroducion.current.environmentImage = this.props.data.wpgraphql.pages.nodes[0].acfDocs.sectionIntroduction[0].modelviewerenvironmentimage.medium;

        // console.log('3D loaded');

        this.modelViewerIntroducion.current.cameraOrbit = '45deg 45deg';
        this.modelViewerIntroducion.current.fieldOfView = 40 + 'deg';

        this.setState({
            poster: 'posterOff'
         })
    }


    resize = () => {
        this.windowType= typeof window !== `undefined` ? getWindowType(window) : 'desktop';
    }



    handleMouseMove = (e) => {
        try {
          if (this.introMouseTimeout) {
              clearTimeout(this.introMouseTimeout)
          }

          const { clientX, clientY } = e;
          const h = window.innerHeight;
          const w = window.innerWidth;
          const yPercentage = clientY / h;
          const xPercentage = clientX / w;
          const rotatex = (Math.floor(xPercentage * 120)) + 'deg';
          const rotatey = (Math.floor(yPercentage * 120)) + 'deg';

          let zoom = (((clientX - (w / 2)) / 10));

          zoom = zoom < 0 ? -zoom : zoom;

          zoom = zoom > 65 ? zoom = 65 : zoom;

          zoom = zoom < 30 ? zoom = 30 : zoom;


          const orbitCycle = (rotatex + rotatey);

          const modelViewerParameters = this.modelViewerIntroducion;

          if (modelViewerParameters.current.model) {
            const [material] = modelViewerParameters.current.model.materials;



            if (!this.introMouseFirstAnimationDone) {
                this.introMouseFirstAnimationDone = true;
                animateMetal(material, 0.2, 1, 500);
                animateRoughness(material, 0.2, 1, 500);
                animateExposure(modelViewerParameters, 2.2, 1.1, 500);
            }
            this.modelViewerIntroducion.current.cameraOrbit = orbitCycle;
            this.modelViewerIntroducion.current.fieldOfView = zoom + 10 + 'deg';
            const lastTimeMouseMoved = new Date().getTime();
            this.introMouseTimeout = setTimeout(() => {
                const currentTime = new Date().getTime();
                if ( this.modelViewerIntroducion.current && currentTime - lastTimeMouseMoved > 500) {
                    animateMetal(material, 1, 0.2, 500)
                    animateRoughness(material, 1, 0.2, 500);
                    animateExposure(modelViewerParameters, 1.1, 2.2, 500);
                    this.introMouseFirstAnimationDone = false;


                }
            }, 1000);
          }
        } catch (e) {
            console.error(e)
        }
    }

    effectOver(sectionIndex) {
        try {
            if (this.modelViewerIntroducion.current.model) {
              const modelViewerParameters = this.modelViewerIntroducion;
              const [material] = modelViewerParameters.current.model.materials;
              this.props.data.wpgraphql.pages.nodes[0].acfDocs.sectionProject.forEach((section, index) => {
                      if (sectionIndex === index) {
                          this.modelViewerIntroducion.current.environmentImage = section.modelviewerenvironmentimage.medium;
                      }
              })
            }
        } catch (e) {
          console.error(e)
        }
    }

    effectOut() {
        try {
            const modelViewerParameters = this.modelViewerIntroducion;
            const [material] = modelViewerParameters.current.model.materials;
        } catch (e) {
          console.error(e)
        }
    }


    render() {

        const data = this.props.data?.wpgraphql?.pages?.nodes[0]

        return (

        <Layout >

            <SEO title="Polygon Dynamics"/>


            <div className={`polygonDynamicsModel ${this.state.poster}`}>

                <model-viewer ref={this.modelViewerIntroducion} interaction-prompt="false" id="modelViewer" min-field-of-view="5deg" min-camera-orbit="Infinity Infinity Infinity" max-camera-orbit="Infinity Infinity Infinity" max-field-of-view="100deg" animation-name="toto" shadow-intensity="0" src="/draco.glb"></model-viewer>
                <div className={`loader ${this.state.poster}`}>
                    <div className="row row___max">
                        <div className="col col8">
                            <div data-uk-spinner="ratio: 1"></div>
                            <h3>Loading 3D element</h3>
                        </div>
                    </div>
                </div>
            </div>


            <section id="textIntro" className="introduction" ref={this.introductionElem}>
                {data && data.acfDocs && data.acfDocs.sectionIntroduction && data.acfDocs.sectionIntroduction.map(( sectionintroduction, index ) => (
                    <div key={index} className="row row___max">
                        <div className="col col8">

                            <h3 className="fontLarge">{sectionintroduction.subtitle}</h3>

                        </div>
                    </div>
                ))}
            </section>

            <section className={`sectionContent aboutPolygon`} >

            <div className="row row___max">
                <div className="col col8">
                    <h3 className="fontLarge">— <b>Polygon Dynamics</b> is a motion and design studio founded by <b><a style={{color: "black"}} href="https://gaspardbruno.com" rel="noopener" target="_blank">Gaspard + Bruno</a></b>, it combines new technologies and processes to craft unique visual experiences and 3d imagery at the edge of fashion; introducing a new approach of digital visualisation without using physical elements.</h3>
                </div>
            </div>


            </section>


            <div className="lightbox lightboxFull">

            {data && data.acfDocs && data.acfDocs.sectionProject && data.acfDocs.sectionProject.map(( section, index ) => (
                section && <div onMouseOver={() => this.effectOver(index)} onMouseOut={() => this.effectOut()} key={index} id={`${section.reference}`} className={`sectionContentFlex ${section.reference}`}>
                        {section.sectionProjectContent && section.sectionProjectContent.map(( media, media_index ) => (
                          media ?
                            media.image ?
                                  <a key={"section_project_content_" + media_index + "-" + index} data-caption={section.texte} className="full" href={media.image.xlarge}>
                                        <div className="fullBg lazyloadState lazyload" style={{backgroundImage: `url(${media.image.small})` }} data-bg={media.image.large}>
                                         </div>
                                  </a>
                            :
                                  media.video && <a key={"section_project_content_" + media_index} href={media.video.mediaItemUrl} data-poster={media.videoposter.medium} data-caption={section.texte}>
                                      <div className="lightboxVideo full">
                                          <video poster={media.video.mediaItemUrl}  className="videoPlayer" uk-video="autoplay: inview" preload="buffer" playsInline loop muted>
                                              <source src={media.video.mediaItemUrl} type="video/mp4"></source>
                                          </video>
                                      </div>
                                  </a>
                            :
                            []
                        ))}
                    {section.legend &&
                            <p className="texte legend">— {section.legend} </p>
                    }
                </div>
            ))}
            </div>
        </Layout>

        )
    }
}

export default Homepage


export const homeQuery = graphql`
query {
  wpgraphql {
    pages(first: 1, where:{ title: "Home page"} ) {
    nodes {
      title
      content
      acfDocs {
          forme3d {
              mediaItemUrl
          }
          sectionIntroduction {
              title
              subtitle
              modelviewerenvironmentimage {
                  sourceUrl
                  medium: sourceUrl(size: MCOVER)
              }
          }
          sectionProject {
              title
              reference
              texte
              legend
              modelviewerenvironmentimage{
                  sourceUrl
                  medium: sourceUrl(size: MCOVER)
              }
              sectionProjectContent {
                  image {
                      small: sourceUrl(size: SCOVER)
                      medium: sourceUrl(size: MCOVER)
                      large: sourceUrl(size: LCOVER)
                      xlarge: sourceUrl(size: XLCOVER)
                  }
                  videoposter{
                      sourceUrl
                      small: sourceUrl(size: SCOVER)
                      medium: sourceUrl(size: MCOVER)
                      large: sourceUrl(size: LCOVER)
                      xlarge: sourceUrl(size: XLCOVER)
                  }
                  video {
                      mediaItemUrl
                  }
                  size
              }
          }
        }
      }
    }
  }
}
`
